import en from "./en.json";
import zh from "./zh.json";

export const resources = {
	"en": {
		translation: en
	},
	"zh": {
		translation: zh
	}
}