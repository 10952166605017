import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts-gl';
import erth from '../static/erth.jpeg';

const initEChartsGL = (chartDom, width, height) => {
  const myChart = echarts.init(chartDom);
  const option = {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    globe: {
      baseTexture: erth,
      // heightTexture: erth,
      displacementScale: 0.1,
      shading: 'lambert',
      light: {
        ambient: {
          intensity: 0.1
        },
        main: {
          intensity: 1.5
        }
      },
      layers: []
    },
    series: []
  };

  myChart.resize({ width, height }); // 调整图表大小

  option && myChart.setOption(option);

  // 返回清除 ECharts 实例的函数
  // return () => {
  //   myChart.dispose();
  // };
}

function EChartsGLDemo({ width, height }) {
  useEffect(() => {
    const chartDom = document.getElementById('EChartsGLDemo');
    if (chartDom) {
      const cleanup = initEChartsGL(chartDom, width, height);
      return cleanup;
    }
  }, [width, height]);

  return <div id="EChartsGLDemo" style={{ width: width, height: height }} />;
  // return <div id="EChartsGLDemo" />;
}

export default EChartsGLDemo;
