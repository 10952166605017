import React, { useState, useEffect, useRef } from "react";
import { Image, Dropdown, Space, Row, Col, Carousel, Affix, Drawer, Button } from "antd";
import { useTranslation } from "react-i18next";
import videoSource from '../../static/homepage_video_background.mp4';
import logoImg from '../../static/logo.jpg';
import enLogo from '../../static/en.jpg';
import zhLogo from '../../static/zh.jpg';
import about1 from '../../static/p1-1.jpg';
import about2 from '../../static/p2-1.jpg';
import about3 from '../../static/p3-1.jpg';
import aiDefi1 from '../../static/img-about-01.jpg';
import aiDefi2 from '../../static/img-about-02.jpg';
import imgClients01 from '../../static/img-clients-01-1.jpg';
import imgClients02 from '../../static/img-clients-02-1.jpg';
import imgClients03 from '../../static/img-clients-03-1.jpg';
import imgClients04 from '../../static/img-clients-04-1.jpg';
import imgClients05 from '../../static/img-clients-05-1.jpg';
import imgClients06 from '../../static/img-clients-06-1.jpg';
import imgClients07 from '../../static/img-clients-07-1.jpg';
import imgClients08 from '../../static/img-clients-08-1.jpg';
import imgClients09 from '../../static/img-clients-09-1.jpg';
import imgClients10 from '../../static/img-clients-010-1.jpg';
import imgClients11 from '../../static/img-clients-011-1.jpg';
import imgClients12 from '../../static/img-clients-012-1.jpg';
import imgClients13 from '../../static/img-clients-013-1.jpg';
import imgClients14 from '../../static/img-clients-014-1.jpg';
import certificate1 from '../../static/certificate1.jpg';
import certificate2 from '../../static/certificate2.jpg';
import certificate3 from '../../static/certificate3.jpg';
import certificate4 from '../../static/certificate4.jpg';
import certificate5 from '../../static/certificate5.jpg';
import certificate6 from '../../static/certificate6.jpg';
import { DownOutlined, TwitterOutlined, MailFilled, EnvironmentFilled, LeftOutlined, RightOutlined, UpSquareFilled, MenuOutlined, CloseOutlined, } from '@ant-design/icons';

import EChartsGLDemo from '../../components/EarthEcharts';
import './index.scss';


const Home: React.FC = (props) => {
  const carouselRef: any = useRef(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [nowLan, setNowLan] = useState(localStorage.getItem('lan') === 'en' ? 'en' : 'zh');
  const [isWebWitdh, setIsWebWitdh] = useState(window.innerWidth >= 920 ? true : false);
  const [showAffix, setShowAffix] = useState(false);
  const [earthWidth, setEarthWidth] = useState('');
  const [earthHeigth, setEarthHeigth] = useState('');


  const changLan = () => {
    if (i18n.language === 'zh') {
      i18n.changeLanguage("en")
      if (!isWebWitdh) {
        setOpenDropdown(false)
        setOpen(false);
      }
      return
    }
    if (!isWebWitdh) {
      setOpenDropdown(false)
      setOpen(false);
    }
    i18n.changeLanguage("zh")
  }

  const responsive = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 9999,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
      },
    },
  ];



  const dropdownRender = () => (
    <div className="dropdownRender__box">
      <div onClick={() => { changLan() }} className="dropdownRender__content">
        {!isWebWitdh && <RightOutlined style={{ marginRight: '20px' }} />}
        <Image
          preview={false}
          src={nowLan === 'zh' ? enLogo : zhLogo
          } />
        <span>{nowLan === 'zh' ? 'English' : '繁體中文'}</span>
      </div>
    </div>
  );

  const setEarthbig = (width: number) => {
    if (width >= 1270) {
      const fontWidth = document.getElementById('earth__font__box')?.clientWidth
      setEarthWidth(fontWidth + 'px');
      setEarthHeigth('1271.33px');
      return
    }
    if (width >= 768) {
      const earthNowWidth = width / 2 - 60;
      const earthNowHeight = width + 'px';
      const fontWidth = document.getElementById('earth__font__box')?.clientWidth
      console.log(fontWidth);
      setEarthWidth(fontWidth + 'px');
      setEarthHeigth(earthNowHeight);
    }
    if (width <= 768) {
      const earthNowWidth = width / 2 - 60;
      const earthNowHeight = width + 'px';
      const fontWidth = document.getElementById('earth__font__box')?.clientWidth
      console.log(fontWidth);
      setEarthWidth(fontWidth + 'px');
      setEarthHeigth(fontWidth + 'px');
    }

  }

  const onClose = () => {
    setOpen(false);
  };

  const handleClick = (event: any, id: string) => {
    // 阻止默认行为，防止页面跳转
    event.preventDefault();

    // 获取目标元素
    const targetElement = document.getElementById(id);
    if (targetElement) {
      // 滚动到目标元素
      targetElement.scrollIntoView({ behavior: 'smooth' }); // 使用平滑滚动效果
    }
    onClose()
  }

  useEffect(() => {
    console.log('初始化是什么语言', i18n.language);
    setNowLan(i18n.language);
    localStorage.setItem('lan', i18n.language);
  }, [i18n.language])
  useEffect(() => {
    const handleResize = () => {
      const witdh = window.innerWidth;
      setEarthbig(witdh)
      setIsWebWitdh(witdh >= 920 ? true : false)
      return witdh >= 920 ? true : false
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollTOTop = () => {
    document.getElementById('homePage')?.scrollTo(0, 0);
  }

  const scrollChange = () => {
    // 监听滚动条距离顶部距离
    const pageScrollTop = document.getElementById('homePage')?.scrollTop || 0
    setShowAffix(pageScrollTop > 100)
  }
  useEffect(() => {
    // 滚动条滚动时触发
    window.addEventListener('scroll', scrollChange, true)
    scrollChange()
    return () => { window.removeEventListener('scroll', scrollChange, false) }
  }, [])

  useEffect(() => {
    setEarthbig(window.innerWidth);
  }, [])

  return (
    <div className='homePage' id="homePage">
      <Drawer
        title={''}
        width={'90%'}
        placement={'right'}
        closable={false}
        onClose={onClose}
        className="DrawerBox"
        open={open}
      >
        <Button type="text" className="CloseBtn" onClick={() => onClose()} icon={<CloseOutlined />} />
        <ul className="header__menu">
          <li onClick={(e) => handleClick(e, 'about')}>
            {t('關於比特大陸')}
          </li>
          <li onClick={(e) => handleClick(e, 'about2')}>
          {t('Ai-Defi聚合器')}
          </li>
          <li onClick={(e) => handleClick(e, 'about3')}>
          {t('比特幣挖礦')}
          </li>
          <li onClick={(e) => handleClick(e, 'about4')}>
          {t('聯繫我們')}
          </li>
          <Dropdown open={openDropdown} destroyPopupOnHide={true} trigger={['click']} overlayClassName='DropdownBox__mobile' dropdownRender={dropdownRender}>
            <li onClick={() => setOpenDropdown(!openDropdown)} className="DropdownBox__mobile__li">
              <div>
                <Image preview={false} width={18} height={12} src={nowLan === 'zh' ? zhLogo : enLogo} />
                {t('繁體中文')}
              </div>
              <div>
                <DownOutlined />
              </div>
            </li></Dropdown>
        </ul>
      </Drawer>
      <div className="homePage__header">
        {isWebWitdh && (<div className='web__content__width homePage__headermain homePage__webHeader'>
          <div className="homePage__logo">
            <Image preview={false} src={logoImg} width={50} />
          </div>
          <div className="homePage__anchor">
            <ul className="header__menu">
              <li><a className="menu__link" href="#about" rel="noopener noreferrer">
                {t('關於比特大陸')}
              </a></li>
              <li><a className="menu__link" href="#about2" rel="noopener noreferrer">
                {t('Ai-Defi聚合器')}
              </a></li>
              <li><a className="menu__link" href="#about3" rel="noopener noreferrer">
                {t('比特幣挖礦')}
              </a></li>
              <li><a className="menu__link" href="#about4" rel="noopener noreferrer">
                {t('聯繫我們')}
              </a></li>
              <Dropdown overlayClassName='DropdownBox' dropdownRender={dropdownRender}>
                <li>
                  <Space>
                    <Image preview={false} width={18} height={12} src={nowLan === 'zh' ? zhLogo : enLogo} /> {t('繁體中文')}
                    <DownOutlined />
                  </Space>
                </li></Dropdown>
            </ul>
          </div>
        </div>)}
        {
          !isWebWitdh && (<div className={showAffix ? 'homePage__headermain homePage__mobileHeader homePage__showAffix' : 'homePage__headermain homePage__mobileHeader'}>
            <div className="homePage__logo">
              <Image preview={false} src={logoImg} width={50} />
            </div>
            <div className="homePage__anchor" onClick={() => setOpen(true)}>
              <MenuOutlined />
            </div>
          </div>)
        }
      </div>
      <div className="homePage__content clearfix">
        <div className="content__video">
          <video className="video__box" autoPlay loop muted playsInline >
            <source src={videoSource} type="video/mp4" />
          </video>
          <div className="video__mark">
            <h1>
              <mark style={{ color: '#f08a26', backgroundColor: 'rgba(0, 0, 0, 0)' }}>{t('比特大陸')}</mark>
              {t('數字資產綜合金融服務提供商')}
            </h1>
            <h3 style={{ color: '#03624c' }}>{t('一鍵開啟高效收益')}</h3>
          </div>
        </div>
        <div className="about__content" id="about">
          <div className="about__box">
            <h2>{t('數字資產的加密避風港')}</h2>
            <p>{t('匯聚全球資源，即刻享受一鍵挖礦')}</p>
            <div>
              <Row gutter={16} className="Image__row">
                <Col xs={24} md={8}>
                  <Image preview={false} src={about1} alt="" />
                  <h6 className="Image__row__span">{t('智能捕捉價值高地的AI程序')}</h6>
                </Col>
                <Col xs={24} md={8}>
                  <Image preview={false} src={about2} alt="" />
                  <h6 className="Image__row__span">{t('快速專業的自研團隊')}</h6>
                </Col>
                <Col xs={24} md={8}>
                  <Image preview={false} src={about3} alt="" />
                  <h6 className="Image__row__span">{t('高效運維快速響應的挖礦團隊')}</h6>
                </Col>
              </Row>

            </div>
            <Row justify={{ 'md': 'space-between' }} gutter={0} className="aboutUs last-child-order">
              <Col xs={24} md={11}>
                <div className="aboutUs__bcakground">
                  <figure className="aboutUs__bcakground__fig">
                    <Image className="aboutUs__bcakground__fig__imgg" preview={false} src={logoImg} alt="" />
                  </figure>
                </div>
              </Col>
              <Col xs={24} md={11}>
                <div className="aboutUs__text">
                  <h2>{t('關於我們')}</h2>
                  <p>{t('值得信賴的託管，世界級的 DeFi 基礎設施')}</p>
                  <p className="has__text__color">{
                  t('比特大陸總部位中國香港，是亞洲快速成長的數字資產綜合金融服務平台之一。旨在為全球範圍內的機構和個人投資者提供簡單、易用的加密金融產品。公司用戶遍布亞洲、非洲、北美和歐洲等地區，服務超過16家機構客戶和數千名高淨值個人客戶。比特大陸主營業務是提供比特幣算力租賃和礦機託管，同時開發了去中心化的Defi投資工具。公司自研的完全去中心化的AI-defi自託管（基於智能合約）解決方案，是目前市場上最佳的defi聚合器之一。')}</p>
                  <p className="has__text__color">
                    {t('為了更好的服務更多全球客戶對於數字資產的保值增值需求，在此依託比特大陸強大的全球業務鏈體系，公司將持續打造從實體比特幣礦場建設託管、算力租賃、Defi流動性挖礦，策略性量化交易，進行多組合多資產的創設和資金募集，比特大陸讓資源不在困於個人投資限制，讓交易策略不在是費心費力的摸索，在比特大陸，一鍵即可開啟穩定高效收益。')}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="about__content" id="about2">
          <div className="about__box">
            <Row justify={{ 'xs': 'end', 'sm': 'end', 'md': 'space-between' }} gutter={0} className="aboutUs">
              <Col xs={24} md={11}>
                <div className="aboutUs__text">
                  {/* 还没录多语言 */}
                  <h2>{t('Ai-Defi聚合器')}</h2>
                  <p>{t('世界級的 DeFi 基礎設施')}</p>
                  <p className="has__text__color">
                    {t('比特大陸 AI- Defi聚合器是屬於DEFI2.0，是一個自動化的投資組合管理器、流動性提供者和價格傳感器，它是支持去中心化鏈上交易和其他EVM兼容系統上代幣的自動化投資組合管理。')}
                  </p>
                  <p className="has__text__color">
                    {t('比特大陸 Ai-Defi聚合器是一個跨鏈資產匯總和收益市場一站式的DeFi產品，將繁瑣的Defi形式Ai化，比特大陸將打造出Ai-Defi流動性挖礦、收益聚合器、交易聚合、理財增強型保險、一鍵式聚合借貸等，產品形態主要為DeFi聚合器平台，滿足資金全方位的金融需求，它給用戶提供了一種增長點，讓他們能夠立即提前了解自己的未來收益率。')}
                  </p>
                  <p className="has__text__color">
                    {t('比特大陸的Ai-Defi聚合器的優勢是去中心化、多策略、高收益的智能聚合器，幫助用戶智能化部署完成Defi挖礦，實時抓取高收益礦池，自動完成複利，產生超高的收益。')}
                  </p>
                  <p className="has__text__color">
                    {t('在比特大陸用戶只需將資金配置到優選的Ai-Defi協議里，Ai-Defi便會自動篩選高收益協議，參與流動性挖礦，一鍵開啟挖礦，還可以跨鏈挖礦，抓取頭礦，讓新手參與的用戶也能輕鬆上手，收穫高收益。')}
                  </p>
                </div>
              </Col>
              <Col xs={24} md={11} className="box-aling-center">
                <div className="aboutUs__bcakground">
                  <figure className="aboutUs__bcakground__fig">
                    <Image className="aiDefi1__img" preview={false} src={aiDefi1} alt="" />
                  </figure>
                </div>
              </Col>
            </Row>
          </div>
          <div className="about__box">
            <Row justify={{ 'xs': 'end', 'sm': 'end', 'md': 'space-between' }} gutter={0} className="aboutUs last-child-order">
              <Col xs={24} md={11} className="box-aling-center">
                <div className="aboutUs__bcakground">
                  <figure className="aboutUs__bcakground__fig">
                    <Image className="aiDefi1__img" preview={false} src={aiDefi2} alt="" />
                  </figure>
                </div>
              </Col>
              <Col xs={24} md={11}>
                <div className="aboutUs__text">
                  <p className="has__text__color">
                    {t('Ai-Defi可以在不同協議和礦池之間切換篩選出最高收益，調用這些合約所花費的Gas費由AI集中操作，用戶共同分攤Gas費，有效降低操作手續費成本。')}
                  </p>
                  <p className="has__text__color">
                    {t('Ai-Defi的用戶資金可隨存隨取，靈活方便，沒有時間限制、沒有金額限制。')}
                  </p>
                  <p className="has__text__color">
                    {t('Ai-Defi將穩定策略池賺取的收益投放到高收益複利策略池，並且在各個高收益池自動複利，每周再自動將收益轉到穩定策略池，不斷複利套利，在保障用戶資金安全的同時持續獲取收益。')}
                  </p>
                  <p className="has__text__color">
                    {t('Ai-Defi是部署在binance smart chain，Ai-Defi部署策略篩選的都是知名安全機構Certik代碼審計過，並且池子流動性高的頭部DeFi協議， 基本確保資金無憂才會開策略池，安全性得到了極大提高。')}
                  </p>
                  <p className="has__text__color">
                    {t('Ai-Defi不僅具有去中心化、智能化、安全高效等特點，同時還具有自動持續復投、低交易手續費、可快速切換策略、時刻瞄準高收益頭礦等諸多優勢，可以讓用戶做到「存入即增 值」的完美體驗。')}
                  </p>
                  <p className="has__text__color">
                    {t('在比特大陸可以不依賴於任何中介,研發團隊將根據市場情況進行持續的技術演化和迭代。Ai-Defi將會成為DeFi 2.0時代的最佳智能捕手！')}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="echartsBox" id="about3">
          <Row justify={{ 'md': 'space-between' }} gutter={0} className="echartsBox__div">
            <Col className="echartsBox__div__earthDemo" xs={24} md={12}>
              <EChartsGLDemo width={earthWidth} height={earthHeigth} />
            </Col>
            <Col id="earth__font__box" className="earth__font__box" xs={24} md={12}>
              <h2>{t('比特幣挖礦')}</h2>
              <p>
                {t('比特大陸的比特幣挖礦業務是在全球不同資源背景下進行了綜合評審和部署，對於全球各地的電價、政策環境進行了深入調查和評審，同時也結合了數字貨幣挖礦的市場規律，在不同幣種的機型搭配上，進行了科學的算力配置，諸如比特幣、萊特幣、狗狗幣、零幣在不同地區的算力搭配都不一樣，讓挖礦作業不在單靠某一個幣種的盈利，增強抵禦對算力結構和市場行情帶來的風險。')}
              </p>
              <p>
                {t('在比特大陸讓個人挖礦不在為繁瑣的機器購買，電力找尋，維修運營去煩惱，這些繁瑣的過程全權由我們處理，全球化的布局可以更好的抵禦市場行情、電價、政策帶來的各種風險，在比特大陸真正實現了挖礦零門檻的跨越，一鍵下單，即可享用全球資源。')}
              </p>
            </Col>
          </Row>
          {/* <div className="echartsBox__div">
            <div className="echartsBox__div__earthDemo"><EChartsGLDemo width={earthWidth} height={earthHeigth} /></div>
            <div className="earth__font__box">
              <h2>比特大陸的執照和合作夥伴</h2>
              <p>
                比特大陸的比特幣挖礦業務是在全球不同資源背景下進行了綜合評審和部署，
                對於全球各地的電價、政策環境進行了深入調查和評審，
                同時也結合了數字貨幣挖礦的市場規律，在不同幣種的機型搭配上，
                進行了科學的算力配置，諸如比特幣、萊特幣、狗狗幣、零幣在不同地區的算力搭配都不一樣，
                讓挖礦作業不在單靠某一個幣種的盈利，增強抵禦對算力結構和市場行情帶來的風險。
              </p>
              <p>
                在比特大陸讓個人挖礦不在為繁瑣的機器購買，電力找尋，維修運營去煩惱，
                這些繁瑣的過程全權由我們處理，全球化的布局可以更好的抵禦市場行情、電價、政策帶來的各種風險，
                在比特大陸真正實現了挖礦零門檻的跨越，一鍵下單，即可享用全球資源。
              </p>
            </div>
          </div> */}
        </div>
        <div className="about__content">
          <div className="about__box">
            <h2>{t('比特大陸的執照和合作夥伴')}</h2>
            <p>{t('公司自主研發生產的礦機加上各大交易所的合作，做到收益最大化。')}</p>
            <div>
              <Row justify={{ 'xs': 'end', 'sm': 'end', 'md': 'space-between' }} gutter={16} className="Image__row">
                <Col xs={12} md={8}>
                  <Image preview={false} src={certificate1} alt="" />
                </Col>
                <Col xs={12} md={8}>
                  <Image preview={false} src={certificate2} alt="" />
                </Col>
                <Col xs={12} md={8}>
                  <Image preview={false} src={certificate3} alt="" />
                </Col>
                <Col xs={12} md={8}>
                  <Image preview={false} src={certificate4} alt="" />
                </Col>
                <Col xs={12} md={8}>
                  <Image preview={false} src={certificate5} alt="" />
                </Col>
                <Col xs={12} md={8}>
                  <Image preview={false} src={certificate6} alt="" />
                </Col>
              </Row>

            </div>
          </div>
        </div>
        {/* <div className="about__content huiBackground">
          <div className="about__box textLeft">
            <h2>{t('比特大陸節點預售')}</h2>
            <p className="has__text__color">{t('限時搶購30天')}</p>
            <p className="has__text__color">{t('礦工節點5000美元（限200個）')}</p>
            <p className="has__text__color">{t('專家節點10000美元（限100個）')}</p>
            <p className="has__text__color">{t('董事節點20000美元（限20個）')}</p>
            <p className="has__text__color">{t('礦工節點')}</p>
            <p className="has__text__color">{t('專家節點')}</p>
            <p className="has__text__color">{t('董事節點')}</p>
            <p className="has__text__color">{t('那些想要與比特大陸共享財富的朋友，請隨時聯繫比特大陸客服 。')}</p>
            <h2>{t('官方地址')}</h2>
            <p className="has__text__color">{t('幣安鏈：0x779af4B30Fa2DAF31d5f90633219C153a134C499')}</p>
            <p className="has__text__color">{t('波場鏈：TNLyD18Sfs6FW7TuRgpsif3PMqLNAZosCC')}</p>
          </div>
        </div> */}

        <div className="about__content">
          <div className="about__box">
            <h2>{t('受到專業機構和個人客戶的信賴')}</h2>
            <p>{t('超過16家機構和數千名個人客戶選擇了比特大陸來存儲、交易、投資、抵押')}</p>
            <Carousel
              ref={carouselRef}
              responsive={responsive}
              autoplay
              dots={false}
              infinite={true}
              arrows={true}
              prevArrow={<LeftOutlined />}
              nextArrow={<RightOutlined />}
            >
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients01} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients02} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients03} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients04} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients05} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients06} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients07} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients08} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients09} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients10} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients11} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients12} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients13} />
              </div>
              <div className="carousel-item">
                <Image className="carousel-item-img" preview={false} src={imgClients14} />
              </div>
              {/* 添加更多的图片 */}
            </Carousel>

          </div>
        </div>
        <div className="about__content huiBackground" id="about4">
          <div className="about__box">
            <h2>{t('聯繫我們')}</h2>
            <div>
              <Row justify={{ 'xs': 'end', 'sm': 'end', 'md': 'space-between' }} gutter={0} className="Image__row">
                <Col className="tellUs__box" xs={24} md={11}>
                  <div><EnvironmentFilled className="tellIcon" /> <span>{t('地址', {nsSeparator: false})}</span> </div>
                  <div><MailFilled className="tellIcon" /> <span>{t('電話:+852-21288448', {nsSeparator: false})}</span> </div>
                  <div><TwitterOutlined className="tellIcon" /> <span>{t('官方客服:BITMAIN-01(MosGram)', {nsSeparator: false})}</span> </div>
                </Col>
                <Col xs={24} md={11}>
                  <Image preview={false} src={about3} alt="" />
                </Col>
              </Row>

            </div>
          </div>
        </div>
        {showAffix && (<Affix offsetBottom={0} style={{ position: 'fixed', bottom: '50px', right: '50px' }}>
          <UpSquareFilled onClick={() => scrollTOTop()} className='AffixIcon' />
        </Affix>)}
        <div className="homePage__footer">
          <p>{t('Copyright © 2024 比特大陸 All Rights Reserved')}</p>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}
export default Home
