import React from "react";
import { 
  Home,
} from "./pages";
import App from './App'

// const isSuperAdmin = sessionStorage.getItem('role') === 'superAdmin';
// console.log(isSuperAdmin, !isSuperAdmin)
// const [ isSuperAdmin, setIsSuperAdmin ] = useState(false);
// useEffect(() => {
//   setIsSuperAdmin(sessionStorage.getItem('role') === 'superAdmin')
// }, [sessionStorage.getItem('role')])

function NotFound () {
  return (
    <div>
      <h1>404</h1>
      <p>对不起，这个页面不存在</p>
    </div>
  )
}
export const routeList = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "*",
    element: <NotFound />
  }
];
// 用户管理，财务管理，投注管理，优惠管理，代理管理，推送管理，机器人管理
